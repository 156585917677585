import IBI_Logo from './components/IBI-logo'
import InfoIcon from './components/info-icon'

import BarChart from './components/charts/BarChart'
import PieChart from './components/charts/PieChart'
import RadarChart from './components/charts/RadarChart'
import Speedometer from './components/charts/Speedometer'

import ReactTooltip from 'react-tooltip'

function App() {
  const data = require('./data/example.json')

  return (
    <>
      <div className="container-fluid">
        <header className="sticky-top bg-white my-3">
          <div className="container">
            <div className="row">
              <div className="col-8 col-xl-9">
                <div className="h-100 d-flex align-items-end">
                  <span className="fundname orange mb-1">{data.pensionFundName}</span>
                </div>
              </div>
              <div className="col-4 col-xl-3">
                <div className="d-flex justify-content-end">
                  <IBI_Logo />
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container">
          <section id="subHeader">
            <div className="row bg-blue text-white">
              <div className="col-9">
                <h1 className="text-uppercase">Uniform pensioenkostenoverzicht</h1>
                <p className="fs-4">Wat zijn de kosten die uw pensioenfonds maakt?</p>
                <p className="fs-4">En wat krijgt u er voor terug?</p>
              </div>

              <div className="col-3 d-flex align-items-end justify-content-center">
                <p className="display-1">UPKO</p>
              </div>
            </div>
          </section>

          <section id="uitvoeringskosten" className="mt-5">
            <div className="row" id="doel">
              <h2 className="sectionHeader">
                1. Doel van het Uniform Pensioenkostenoverzicht (UPKO)
                {/* voorbeeld InfoIcon
                  <a
                  href="#" d ata-delay-show='350'
                  data-class="uitleg"
                  data-html={true}
                  onClick={(e) => e.preventDefault()}
                  className="d-inline-block ms-2 text-none"
                  data-tip="<p>Info icon om meer informatie te geven.<BR />Dit kan ook in meerdere regels?</p>"
                >
                  <InfoIcon />
                </a>
              */}
              </h2>


              <div className="col-12">
                <div className="row">
                  <div className="col-3">
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <svg data-name="Layer 1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="#F09743" width="100" height="100">
                        <path transform="scale (-1, 1)" transform-origin="center" d="M50 5a44.76 44.76 0 00-24.92 7.54l-7.13-7.13a1.41 1.41 0 00-2.4 1v9.14H6.41a1.41 1.41 0 00-1 2.4l7.13 7.13A45 45 0 1050 5zM26.8 18.24v6.57l-8.44-8.44V9.8zm13.32 23.87a12.67 12.67 0 102-2l-8.53-8.53a24.72 24.72 0 11-2 2zm7.18 7.18a2.86 2.86 0 102-2l-5.16-5.16a9.88 9.88 0 11-2 2zM16.37 18.36l8.44 8.44h-6.57L9.8 18.36zM50 92.19a42.15 42.15 0 01-35.42-65.07l2.08 2.08a1.41 1.41 0 001 .41h10l2 2a27.5 27.5 0 102-2l-2-2v-10a1.41 1.41 0 00-.41-1l-2.08-2.08A42.18 42.18 0 1150 92.19z"></path>
                      </svg>
                    </div>
                  </div>

                  <div className="col-6">
                    <p>
                      UPKO geeft een overzicht van alle kosten van het pensioenfonds en laat zien hoe die kosten zich verhouden tot de prestaties van het fonds en die van andere pensioenfondsen,
                      zoals het rendement op de beleggingen en de service aan de deelnemers.
                      Door ze te vergelijken kunnen de prestaties van het eigen pensioenfonds beter beoordeeld worden. 
                        {/* voorbeel data tooltip
                        <a className="orange a_tooltip" href="#" onClick={(e) => e.preventDefault()} data-tip="Prestaties zijn onder meer het behaalde rendement op het vermogen, maar ook de geboden service, kwaliteit van de dienstverlening automatiseringsgraad, etc.">prestaties</a>
                        */} 
                    </p>
                  </div>
                </div>
              </div>

            </div>


            <div className="row">

              <div className="col-6" id="doel">
                <h3>De kosten van uw pensioen</h3>

                <div className="col-12">
                  <p>De kosten (exclusief betaalde pensioenpremies) bestaan uit drie categorieën: </p>
                  <ul style={{ paddingLeft: '1.2rem', litStyleType: '' }} >
                    <li>Vermogensbeheer, de kosten van beleggen: </li>
                    <li style={{ listStyleType: 'none' }}>
                      <ul style={{ listStyleType: 'circle' }} >
                        <li>€ {data.VBK_Mln} miljoen</li>
                        <li>{data.VBK_AUM_Procent}% beheerd vermogen ({data.VBK_AUM_Bps} bps) </li>
                      </ul>
                    </li>
                    <li>
                      Transacties, aan- en verkoopkosten:
                    </li>
                    <li style={{ listStyleType: 'none' }}>
                      <ul style={{ listStyleType: 'circle' }} >
                        <li>€ {data.TK_Mln} miljoen</li>
                        <li>{data.TK_AUM_Procent}% van beheerd vermogen ({data.TK_AUM_Bps} bps)</li>
                      </ul>
                    </li>
                    <li>
                      Pensioenbeheer, de kosten voor het uitvoeren van de pensioenadministratie:
                    </li>
                    <li style={{ listStyleType: 'none' }}>
                      <ul style={{ listStyleType: 'circle' }} >
                        <li>€ {data.PBK_Mln} miljoen</li>
                        <li>€ {data.PBK_DeelnemerPF} per deelnemer</li>
                      </ul>
                    </li>
                  </ul>
                  <p className="mt-1">Kosten van vermogensbeheer en de transactiekosten bepalen voor een groot deel de kosten van het pensioenfonds</p>
                </div>

                <h3>Over benchmarking en IBI</h3>
                <p>
                  Het Institutioneel Benchmarking Instituut benchmarkt sinds 2012 de kosten van Nederlandse
                  pensioenfondsen op basis van wet- en regelgeving. Bij benchmarking worden de
                  kosten en prestaties van het pensioenfonds afgezet tegen een groep van vergelijkbare
                  pensioenfondsen, voor aantal deelnemers of beheerd vermogen. Zo wordt zichtbaar op
                  welke gebieden het fonds het goed doet en op welke vlakken er verbeteringen mogelijk
                  zijn.
                </p>
              </div>

              <div className="col-6" id="kostenverdeling">
                <div className="row">

                  <div className="col-12">
                    <h3>Verdeling van kosten in percentage van het totaal</h3>

                    <div className="row">
                      <div className="col-12 col-xl-6">
                        <div className="row">

                          <div className="col-12">
                            <h4 className="text-uppercase text-center">Uw pensioenfonds</h4>

                            <div className="piechart-wrapper-large d-flex flex-column">
                              <PieChart data={data.uitvoeringskosten_fonds} arcLabelsRadiusOffset={0.85} legenda />
                              <div className="alert alert-light alert-dismissible position-absolute shadow-lg w-75 align-self-center print-hidden" style={{ top: '65%', left: '40%' }} role="alert">
                                Alle figuren zijn interactief. Klik erop voor meer informatie.
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="col-12 col-xl-6">
                        <div className="row">

                          <div className="col-6 col-xl-12">
                            <h4 className="text-uppercase text-center">Verdeling bij vergelijkbare fondsen</h4>

                            <div className="piechart-wrapper-small">
                              <PieChart data={data.uitvoeringskosten_peers} arcLabelsRadiusOffset={0.8} />
                            </div>
                          </div>

                          <div className="col-6 col-xl-12 d-flex flex-column justify-content-end mt-xl-2">
                            <h4 className="text-uppercase text-center">Verdeling bij alle fondsen</h4>

                            <div className="piechart-wrapper-small">
                              <PieChart data={data.uitvoeringskosten_universum} arcLabelsRadiusOffset={0.8} />
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </section>

          <section id="keuzes" className="row">

            <div className="row" id="keuzes-algemeen">
              <h2 className="sectionHeader">2. Welke keuzes heeft het fonds gemaakt?</h2>

              <div className="col-6" id="achtergrondinfo">
                <h3>Kosten zijn het gevolg van keuzes van het fonds</h3>
                <p>
                  Sociale partners beslissen over de inhoud van de pensioenregeling en de wijze waarop de
                  regeling wordt uitgevoerd. Binnen deze afspraken beslist het bestuur van het pensioenfonds,
                  bijvoorbeeld, in welke beleggingen wordt geïnvesteerd en hoe dat gebeurt, of
                  welke service en informatie wordt gegeven aan de deelnemers van het pensioenfonds.
                </p>
                <p className="mt-3">
                  De keuzes gemaakt door het bestuur leiden tot verschillen in kosten, maar bepalen ook de
                  prestatie van het pensioenfonds. Tegenover de kosten van beleggen staat wat de beleggingen
                  opleveren (rendement). Bij de pensioenbeheerkosten (o.a. bijhouden van de
                  administratie per deelnemer, het betalen van pensioenen en incasseren van de pensioenpremies
                  bij de werkgever) moeten de kosten afgezet worden tegen het serviceniveau en de
                  complexiteit van de pensioenregeling die moet worden uitgevoerd door het pensioenfonds.
                </p>
              </div>

              <div className="col-6" id="reserve">
                <h3>Genomen risico bepaalt omvang van de reserve</h3>

                <div className="row">
                  <div className="col-12 col-xl-6">
                    <p>
                      Een pensioenfonds dat meer risico neemt bij het beleggen moet een hogere reserve
                      aanhouden om eventuele tegenvallers op te kunnen vangen. Ook heeft de huidige
                      financiële situatie van het fonds invloed op hoeveel risico het fonds kan en mag
                      nemen. Een hoger risico levert in de regel een hoger rendement.
                    </p>
                  </div>

                  <div className="col-12 col-xl-6 mt-3 mt-xl-0 order-xl-first">
                    <Speedometer data={data.toetswaarde_buffer} />
                  </div>

                  <div className="col-12 mt-3">
                    <div className="row justify-content-center">
                      <div className="col-6 col-lg-5">
                        <div className="bg-blue text-white text-uppercase text-center p-2">
                          Range alle deelnemende pensioenfondsen
                        </div>
                      </div>
                      <div className="col-6 col-lg-5">
                        <div className="bg-beige text-uppercase text-center p-2">
                          Range vergelijkbare pensioenfondsen
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>

          {/*=============== 3. SECTION VERMOGENSBEHEER ===*/}
          <section id="vermogensbeheer">
            <div className="row" id="vermogensbeheer-info">
              <h2 className="sectionHeader">3. Vermogensbeheer: Hoe heeft uw pensioenfonds gepresteerd?</h2>
            </div>

            {/*---------- 3.1 SUB-SECTION RENDEMENT ----*/}

            <div className="row" id="rendement">
              <h2>3.1 Rendement (%)</h2>

              <div className="col-6">
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <h3>10-jarig gemiddelde</h3>
                    <Speedometer data={data.rendement_totaal_10jaargem} />
                  </div>
                  <div className="col-12 col-xl-6 mt-3 mt-xl-0">
                    <h3>5-jarig gemiddelde</h3>
                    <Speedometer data={data.rendement_totaal_5jaargem} />
                  </div>
                </div>
              </div>

              <div className="col-6">
                <h3>Rendement verslagjaar</h3>
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <p>
                      De hoogte van het rendement wordt voornamelijk bepaald door de beleggingscategorieën
                      waarin belegd wordt. Bij een vergelijking van het rendement met andere pensioenfondsen
                      geeft een periode van 5 en 10 jaar een beter beeld van het behaalde rendement in
                      relatie tot het beleggingsbeleid waarvoor het bestuur heeft gekozen.
                    </p>
                  </div>

                  <div className="col-12 col-xl-6 mt-3 mt-xl-0 order-xl-first">
                    <Speedometer data={data.rendement_totaal} />
                  </div>
                </div>
              </div>
            </div>
            {/*---------- 3.2 SUB-SECTION KOSTEN VB ----*/}

            <div className="row" id="kosten">
              <h2>3.2 Kosten (in basispunten) <span className="info-icon">
                <a
                  href="#"
                  data-class="uitleg"
                  data-html={true}
                  onClick={(e) => e.preventDefault()}
                  className="d-inline-block ms-2 text-none"
                  data-tip="<p>100 basispunten (of bps) is 1% van het beheerde vermogen van het pensioenfonds</p>"
                >
                  <InfoIcon />
                </a></span>
              </h2>



              <div className="col-6" id="vbk">
                <h3>Kosten vermogensbeheer in bps</h3>
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <p className="mb-2">
                      Benchmarkkosten is een door het Institutional Benchmarking Institute onafhankelijk
                      berekende kostennorm die past bij de door het pensioenfonds gekozen wijze
                      van beleggen. Indien de kosten van het pensioenfonds hoger zijn dan de berekende
                      benchmarkkosten, dan zijn de kosten van het pensioenfonds hoger dan
                      vergelijkbare fondsen met een gelijke portefeuille.
                    </p>
                    <p><span className="orange" style={{ fontSize: '12px' }}>
                      *) 100 basispunten (bps) is 1% van beheerd vermogen
                    </span></p>
                  </div>

                  <div className="col-12 col-xl-6 mt-3 mt-xl-0 order-xl-first">
                    <Speedometer data={data.kosten_vermogensbeheer} />
                  </div>
                </div>
              </div>

              <div className="col-6" id="tk">
                <h3>Transactiekosten in bps</h3>
                <div className="row">
                  <div className="col-12 col-xl-6">
                    <p>
                      De jaarlijkse inleg wordt belegd, hierdoor ontstaan aankoopkosten. Ook herschikkingen
                      en prijsveranderingen zorgen voor transacties, net zoals actief beheer.
                      De hoogte van de aan- en verkoopkosten van beleggingen wordt bepaald door het
                      aantal transacties en het type belegging wordt bepaald waarin belegd wordt.
                    </p>
                  </div>

                  <div className="col-12 col-xl-6 mt-3 mt-xl-0 order-xl-first">
                    <Speedometer data={data.transactiekosten} />
                  </div>
                </div>
              </div>
            </div>
            {/*---------- 3.3 SUB-SECTION KEUZES VERMOGENSBEHEER ----*/}

            <div className="row" id="vermogensbeheer-keuzes">
              <h2>3.3 Beleggingsbeleid, welke keuzes heeft het pensioenfonds gemaakt?</h2>

              <div className="row" id="keuzes-vb-aa-index">
                <div className="col-12">
                  <h3>Hoe gespreid wordt het vermogen belegd?</h3>
                </div>

                <div className="col-6">
                  <p>
                    Sommige pensioenfondsen beleggen alleen in obligaties en aandelen. Andere beleggen
                    daarnaast ook in bijvoorbeeld hypotheken en vastgoed. Een brede spreiding leidt tot
                    hogere kosten maar een lager risico. De gemiddelde spreiding is 100.
                  </p>

                  <p className="mt-3">
                    De spreiding heeft de grootste invloed op de kosten van beleggen.
                  </p>
                </div>

                <div className="col-6 mt-1">
                  <div style={{ height: '150px' }}>
                    <BarChart data={data.aa_index} />
                  </div>
                </div>

                <div className="row mt-5" id="keuzes-vb-ai-index">
                  <div className="col-12">
                    <h3>In welke mate wordt actief een hoger rendement nagestreefd?</h3>
                  </div>

                  <div className="col-6">
                    <p>
                      Door actief te beleggen proberen pensioenfondsen een hoger rendement te halen dan
                      het gemiddelde van de markt. Hier tegenover staat passief beheer, waarbij het fonds de
                      markt volgt. Het gemiddelde is 100.
                    </p>

                    <p className="mt-3">
                      Actief beleggen leidt tot hogere kosten met als doel een hoger rendement.
                    </p>
                  </div>

                  <div className="col-6 mt-1">
                    <div style={{ height: '150px' }}>
                      <BarChart data={data.ai_index} />
                    </div>
                  </div>
                </div>


                <div className="row mt-5" id="keuzes-vb-ii-index">
                  <div className="col-12">
                    <h3>Hoe worden de beleggingen gedaan?</h3>
                  </div>

                  <div className="col-6">

                    <p>
                      Het pensioenfonds kan zelf beleggingen houden of investeren in bestaande beleggingsfondsen.
                      Zelf houden is duurder, maar biedt meer vrijheid. Het gemiddelde is 100.
                    </p>

                    <p className="mt-3">
                      Hoe hoger deze waarde, hoe meer het fonds via beleggingsfondsen belegd.
                    </p>
                  </div>

                  <div className="col-6 mt-1">
                    <div style={{ height: '150px' }}>
                      <BarChart data={data.ii_index} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*---------- 3.4 SUB-SECTION RADAR VERMOGENSBEHEER ----*/}

          <section id="section4">
            <div className="row" id="vermogensbeheer-radar">
              <h2 className="mt-5">3.4 Kosten van beleggen in context</h2>

              <div className="row">
                <div className="col-6">
                  <p>
                    Onderstaande spingrafiek geeft een overzicht van de behaalde rendementen en kosten en zet
                    deze in verhouding tot de keuzes die het pensioenfonds gemaakt heeft en die de uitkomsten
                    bepalen.
                  </p>
                </div>
                <div className="col-6">
                  <p>
                    Voor de grafiek zijn de cijfers geïndexeerd. Dit betekent dat het gemiddelde van alle deelnemende
                    fondsen een waarde van 100 heeft. De grafiek laat zien hoe het pensioenfonds scoort
                    vergleken met andere, en vooral vergelijkbare pensioenfondsen. Benchmarkkosten hoger dan
                    100 betekent dat kosten hoger zijn dan verwacht.
                  </p>
                </div>
              </div>

              <div className="col-12">
                <div className="radar-wrapper">
                  <div className="radar-division-line-wrapper print-hidden">
                    <div className="radar-division-line"></div>
                  </div>

                  <RadarChart data={data.radar_vb.values} />

                  <div className="d-flex justify-content-center print-hidden">
                    <p className="w-25 text-uppercase text-center">{data.radar_vb.labelLeft}</p>
                    <p className="w-25 text-uppercase text-center">{data.radar_vb.labelRight}</p>
                  </div>
                </div>
              </div>

            </div>
          </section>

          {/*---------- 4 SECTION RADAR PENSIOENBEHEER ----*/}

          <section id="pensioenbeheer">
            <div className="row" >
              <h2 className="sectionHeader">4. Pensioenbeheer</h2>

              <h2>4.1 Wat zijn de kosten per deelnemer? </h2>

              <div className="col-9 offset-1">
                <p>
                  Bij pensioenbeheerkosten kan het bestuur niet alles beslissen. Op het aantal deelnemers en
                  de samenstelling van de deelnemers heeft het bestuur geen invloed. Met name de niet actieve
                  deelnemers (slapers) hebben grote invloed op de kosten per deelnemer. Ook het aantal
                  waarde-overdrachten (deelnemers die met reeds opgebouwde vermogen toetreden of vertrekken
                  bij het pensioenfonds) heeft invloed op het kostenniveau.
                </p>
              </div>

              <div className="col-6" id="pensioenbeheer-pbk-pf">
                <h3>Pensioenbeheerkosten <br /> (werknemers en gepensioneerden)</h3>

                <div className="row">
                  <div className="col-12 col-xl-6">
                    <p>
                      Kosten per deelnemer indien uitgegaan wordt van het aantal premiebetalende deelnemers
                      en gepensioneerden aan het eind van het verslagjaar. Dit is de afgesproken manier voor
                      het berekenen van de kosten per deelnemer die ook wettelijk gepubliceerd moet worden.
                    </p>
                  </div>
                  <div className="col-12 col-xl-6 mt-3 mt-xl-0 order-xl-first">
                    <Speedometer data={data.DeelnemersKostenPB_DefPF} />
                  </div>
                </div>
              </div>

              <div className="col-6" id="pensioenbeheer-pbk-alle">
                <h3>Pensioenbeheerkosten <br /> (alle deelnemers)</h3>

                <div className="row">
                  <div className="col-12 col-xl-6">
                    <p>
                      Aanvullend kijken wij ook naar de kosten per deelnemer indien uitgegaan wordt van alle
                      deelnemers, dus ook de ‘slapers’. Hoewel het bestuur geen invloed heeft op het aantal
                      slapers, hebben deze alsnog invloed op de kosten.
                    </p>
                  </div>
                  <div className="col-12 col-xl-6 mt-3 mt-xl-0 order-xl-first">
                    <Speedometer data={data.DeelnemersKostenPB_Alle} />
                  </div>
                </div>
              </div>
            </div>

            {/*---------- 4.2 SUB-SECTION KEUZES PENSIOENBEHEER  ----*/}

            <div className="row" id="pensioenbeheer-keuzes">
              <h2>4.2 Welke keuzes heeft het pensioenfonds gemaakt?</h2>
              <div className="col-12">
                <div className="row" id="keuzes-pb-service-index">
                  <div className="col-12">
                    <h3>Welke service is afgesproken met werkgever en sociale partners?</h3>
                  </div>

                  <div className="col-6">
                    <p>
                      De mate van service verschilt per pensioenfonds o.a. op het gebied van tijdig verwerken
                      van pensioenbetalingen, openingsuren helpdesk, website moge-lijkheden en afwerking
                      van klachten. Het gemiddelde is 100.
                    </p>

                    <p className="mt-3">
                      Hogere service leidt in de regel tot hogere kosten.
                    </p>
                  </div>

                  <div className="col-6 mt-1">
                    <div style={{ height: '150px' }}>
                      <BarChart data={data.service_index} />
                    </div>
                  </div>
                </div>

                <div className="row mt-5" id="keuzes-pb-automatiserings-index">
                  <div className="col-12">
                    <h3>In hoeverre is de administratie geautomatiseerd?</h3>
                  </div>

                  <div className="col-6">
                    <p>
                      Indien processen zijn geautomatiseerd, dan zijn de kosten van verwerking lager dan
                      indien een groot gedeelte van de werkzaamheden handmatig moet worden uitgevoerd.
                      Het gemiddelde is 100.
                    </p>

                    <p>
                      Een hogere automatiseringsgraad leidt in de regel tot lagere kosten. Het is voor grote
                      fondsen makkelijker te realiseren dan voor kleinere pensioenfondsen.
                    </p>
                  </div>

                  <div className="col-6 mt-1">
                    <div style={{ height: '150px' }}>
                      <BarChart data={data.automatiserings_index} />
                    </div>
                  </div>
                </div>

                <div className="row mt-5" id="keuzes-pb-complexiteits-index">
                  <div className="col-12">
                    <h3>Hoe complex is de pensioenadministratie?</h3>
                  </div>

                  <div className="col-6">
                    <p>
                      De complexiteit van een uit te voeren pensioenregeling is een gevolg van de afspraken
                      die met de werkgever en sociale partners over het pensioen zijn gemaakt. De gemiddelde
                      complexiteit is 100. Ook het type pensioenfonds beïnvloedt de complexiteit.
                    </p>

                    <p className="mt-3">
                      Een complexere pensioenadministratie is bewerkelijker en duurder om uit te voeren.
                    </p>
                  </div>

                  <div className="col-6 mt-1">
                    <div style={{ height: '150px' }}>
                      <BarChart data={data.complexiteits_index} />
                    </div>
                  </div>
                </div>

                <div className="row mt-5" id="keuzes-pb-overdrachten-index">
                  <div className="col-12">
                    <h3>Aantal overdachten van pensioenen?</h3>
                  </div>

                  <div className="col-6">
                    <p>
                      Bij het veranderen van werkgever is het mogelijk het opgebouwd pensioen mee te
                      nemen naar het pensioenfonds van de nieuwe werkgever. Het gemiddelde aantal
                      overdrachten is 100.
                    </p>

                    <p>
                      Veel in- en uitgaande overdrachten leidt tot extra kosten.
                    </p>
                  </div>

                  <div className="col-6 mt-1">
                    <div style={{ height: '150px' }}>
                      <BarChart data={data.overdrachten_index} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*---------- 4.3 SUB-SECTION RADAR PENSIOENBEHEER  ----*/}
          <section id="section6">
            <div className="row">
              <h2 className="mt-5">4.3 Pensioenbeheerkosten in context</h2>
            </div>

            <div className="col-10 offset-1">
              <p>
                De spingrafiek geeft een overzicht van de pensioenbeheerkosten per deelnemer.
              </p>
              <p className="mt-3">
                Deze geïndexeerde kosten per deelnemer worden in relatie gezet tot keuzes van het bestuur
                die van invloed zijn op de kosten van pensioenbeheer, zoals het serviceniveau, de complexiteit
                voor het uitvoeren van de pensioenregeling en de mate van automatisering.
                Op het aantal overdrachten en de samenstelling van de deelnemers heeft het bestuur geen
                invloed, hoewel deze de kosten alsnog beïnvloeden.
              </p>
              <p className="mt-3">
                Het gemiddelde van alle deelnemende fondsen heeft een waarde van 100. De grafiek laat
                zien hoe het pensioenfonds scoort vergleken met andere, en vooral vergelijkbare pensioenfondsen.
              </p>
            </div>

            <div className="col-12">
              <div className="radar-wrapper">
                <div className="radar-division-line-wrapper print-hidden">
                  <div className="radar-division-line"></div>
                </div>

                <RadarChart data={data.radar_pb.values} />

                <div className="d-flex justify-content-center print-hidden">
                  <p className="w-25 text-uppercase text-center">{data.radar_pb.labelLeft}</p>
                  <p className="w-25 text-uppercase text-center">{data.radar_pb.labelRight}</p>
                </div>
              </div>
            </div>
            <div className="col-6 offset-6 mb-3" id="radar-extra-info">
              <p>
                <span className="orange" style={{ fontSize: '12px' }}>
                  *) Kosten volgens de Pensioenfederatie: aantal actieve werknemers en gepensioneerden. Stand einde jaar.<br />
                  **) Kosten o.b.v. alle deelnemers, incl. de voormalige werknemers. Gemiddelde gedurende het jaar.
                </span>
              </p>
            </div>
          </section>

          <section id="section7">
            <footer className="row pt-2 pb-4">
              <div className="col-10 offset-1">
                <p className="pt-2">
                  De kosten van UPKO (inclusief het benchmarkrapport op basis waarvan dit overzicht is opgesteld)
                  bedraagt voor dit fonds € {new Intl.NumberFormat('nl-NL', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(data.UPKO_Cost)} per deelnemer.
                </p>
                <p className="mt-2">
                  De informatie in deze rapportage is met de grootst mogelijke zorgvuldigheid vergaard en
                  samengesteld. Aan de inhoud van deze rapportage kunnen geen rechten worden ontleend of
                  aanspraken worden gemaakt. Alle rechten voorbehouden Institutional Benchmarking Institute BV.
                </p>
                <p className="text-center mt-3" style={{ fontStyle: 'italic', color: "grey" }}>
                  Copyright Institutional Benchmarking Institute {new Date().getFullYear()}
                </p>
              </div>
            </footer>
          </section>

        </div>
      </div>
      <ReactTooltip type="dark" effect="solid" />
    </>
  );
}

export default App;
