import GaugeChart from './GaugeChart'

const Speedometer = ({ data: { minU, maxU, minP, maxP, unit, needle_values } }) => {
  const ringWidth = 25
  const colors = ['#858585', '#BACBE4', '#EC7F0D', '#860040']

  needle_values.map((needle, index) => needle.color = colors[index])

  return (
    <div style={{ position: 'relative', height: '75%' }}>
      <div className="legenda">
        {
          needle_values.map(({ id, value, color }) => (
            <div key={id}>
              <div style={{ width: '12px', height: '12px', display: 'inline-block', backgroundColor: color, marginRight: '.5rem' }}></div>
              {id}:
              <strong style={{ marginLeft: '.25rem' }}>{value}{unit}</strong>
            </div>
          ))
        }
      </div>

      <div className="speedometer-parent" style={{ position: 'relative' }}>
        <GaugeChart
          forceRender={true}
          ringWidth={ringWidth}
          height={200}
          needles={needle_values}
          segments={1}
          segmentColors={['#18376D']}
          minValue={minU ?? 0}
          maxValue={maxU ?? 100}
          minLabel={minU ?? 0}
          maxLabel={maxU ?? 100}
          valueTextFontSize={16}
          valueTextFontFamily="sans-serif"
        />
        <div className="speedometer-helper" style={{ zIndex: '-1' }}>
          <GaugeChart
            height={200}
            ringWidth={ringWidth * 2}
            customSegmentStops={[minU, minP, maxP, maxU]}
            segmentColors={['#18376D', '#F6EFE3', '#18376D']}
            minValue={minU ?? 0}
            maxValue={maxU ?? 100}
          />
        </div>
        <div className="speedometer-helper" style={{ opacity: 0 }}>
          <GaugeChart
            height={200}
            ringWidth={ringWidth * 2}
            customSegmentStops={[minU, minP, maxP, maxU]}
            segmentColors={['#18376D', '#F6EFE3', '#18376D']}
            minValue={minU ?? 0}
            maxValue={maxU ?? 100}
          />
        </div>
      </div>
    </div>
  )
}

export default Speedometer