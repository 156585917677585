import { ResponsivePie } from '@nivo/pie'

const PieChart = ({ data, arcLabelsRadiusOffset, legenda }) => {
    const colors = ['#18376D', '#F57F17', '#B8CCE4']

    data.map((input, index) => input.color = colors[index])

    return (
        <>
            <div className={`${legenda ? 'h-75' : 'h-100'} position-relative`}>
                <ResponsivePie
                    data={data}
                    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                    activeOuterRadiusOffset={10}
                    enableArcLinkLabels={false}
                    arcLabelsTextColor="#ffffff"
                    arcLabelsRadiusOffset={arcLabelsRadiusOffset}
                    colors={colors}
                    theme={{ "fontSize": 16 }}
                />
                <div className="rounded-circle pie-circle print-hidden"></div>
            </div>
            {
                legenda && <div className="legenda pt-4 ps-5">
                    {
                        data.map(({ id, value, color }) => (
                            <div key={id}>
                                <div style={{ width: '12px', height: '12px', display: 'inline-block', backgroundColor: color, marginRight: '.5rem' }}></div>
                                {id}:
                                <strong style={{ marginLeft: '.25rem' }}>{value}</strong>
                            </div>
                        ))
                    }
                </div>
            }

        </>
    )
}

export default PieChart