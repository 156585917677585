const ibiLogo = () => {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 403 150" width="100%" xmlSpace="preserve">
            <g id="nederlands">
                <text id="Institutioneel" transform="matrix(1 0 0 1 80.271 57.7271)" fill="#58585A" fontFamily="'PT Sans'" fontSize="55.8564">Institutioneel</text>
                <text transform="matrix(1 0 0 1 -2.2998 99.6333)" fill="#916126" fontFamily="'PT Sans'" fontSize="55.8564">Benchmarking</text>
                <text id="Instituut" transform="matrix(1 0 0 1 112.9302 140.8247)" fill="#15274D" fontFamily="'PT Sans'" fontSize="55.8564">Instituut</text>
            </g>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
            </style>
        </svg>
    )
}

export default ibiLogo