import { ResponsiveRadar } from '@nivo/radar'
import React from 'react'

const RadarChart = ({ data }) => {
    const rotation = '12.86'
    const keys = ['Alle deelnemende pensioenfondsen', 'Vergelijkbare pensioenfondsen', 'Fonds']
    const colors = ['#afadac', '#B8CCE4', '#F57F17']
    return (
        <>
            <div className="legenda position-absolute" style={{ top: '1rem' }}>
                {
                    keys.map((key, index) => (
                        <div key={key}>
                            <div style={{ width: '12px', height: '12px', display: 'inline-block', backgroundColor: colors[index], marginRight: '.5rem' }}></div>
                            {key}
                        </div>
                    ))
                }
            </div>
            <div className="radar h-100" style={{ transform: `rotate(${rotation + 'deg'})` }}>
                <ResponsiveRadar
                    data={data}
                    keys={keys}
                    indexBy="id"
                    margin={{ top: 80, right: 210, bottom: 70, left: 210 }}
                    gridLevels={7}
                    gridLabelOffset={12}
                    enableDots={false}
                    enableDotLabel={false}
                    colors={colors}
                    borderColor={{ from: 'color' }}
                    blendMode="multiply"
                    theme={{ "fontSize": 16 }}
                    gridLabel={({ id, x, y, anchor }) => {
                        const category = data.find(category => category.id === id)
                        const myRef = React.createRef()
                        console.log(myRef)
                        const label = `<tspan ref=${myRef} textAnchor="end" x="0">${id}</tspan>`
                        console.log(label)
                        return (
                            <g transform={`translate(${x}, ${y})`}>
                                <g transform={`rotate(-${rotation})`}>
                                    <text
                                        dominantBaseline="hanging"
                                        // Normally the first label is centered, but since we slightly rotated the radar graph, we want the first label to align from the start.
                                        // All other labels keep the anchor input.
                                        textAnchor={id === data[0].id ? "start" : anchor}
                                        style={{ fontFamily: 'sans-serif', fontSize: '16px', fill: 'rgb(51, 51, 51) ' }}
                                    >
                                        <tspan>{id}</tspan>
                                        <tspan className="print-visible"
                                            x={anchor === 'end' ? '0' : '1rem'}
                                            dy="1rem"
                                        >
                                            {category['Alle deelnemende pensioenfondsen']}
                                        </tspan>
                                        <tspan className="print-visible"
                                            x={anchor === 'end' ? '0' : '1rem'}
                                            dy="1rem"
                                        >
                                            {category['Vergelijkbare pensioenfondsen']}
                                        </tspan>
                                        <tspan className="print-visible"
                                            x={anchor === 'end' ? '0' : '1rem'}
                                            dy="1rem"
                                        >
                                            {category['Fonds']}
                                        </tspan>
                                    </text>
                                    <rect className="print-visible"
                                    x={anchor === 'end' ? '-2.5rem' : '0'}
                                    y="1rem" width="12" height="12" style={{ fill: '#afadac' }} />
                                    <rect className="print-visible"
                                    x={anchor === 'end' ? '-2.5rem' : '0'}
                                    y="2rem" width="12" height="12" style={{ fill: '#B8CCE4' }} />
                                    <rect className="print-visible"
                                    x={anchor === 'end' ? '-2.5rem' : '0'}
                                    y="3rem" width="12" height="12" style={{ fill: '#F57F17' }} />
                                </g>
                            </g>
                        )
                    }}
                />
            </div>
        </>
    )
}

export default RadarChart