import { ResponsiveBar } from '@nivo/bar'

const BarChart = ({ data: { minU, maxU, minP, maxP, values } }) => {
    const data = [{
        'begin': minP - minU,
        'midden': maxP - minP,
        'eind': maxU - maxP
    }]
    const colors = ['#858585', '#B8CCE4', '#F57F17']

    values.map((needle, index) => needle.color = colors[index])

    return (
        <div className="h-100">
            <div className="legenda">
                {
                    values.map(({ id, value, color }) => (
                        <div key={id}>
                            <div style={{ width: '12px', height: '12px', display: 'inline-block', backgroundColor: color, marginRight: '.5rem' }}></div>
                            {id}:
                            <strong style={{ marginLeft: '.25rem' }}>{value}</strong>
                        </div>
                    ))
                }
            </div>
            <div className="d-flex h-50 align-items-end mt-5">
                <strong className="me-2">{minU}</strong>

                <div className="h-100 w-100 mb-4" style={{ borderBottom: '1px solid' }}>
                    <ResponsiveBar
                        data={data}
                        keys={['begin', 'midden', 'eind']}
                        padding={0.2}
                        layout="horizontal"
                        colors={['#18376D', '#F6EFE3']}
                        isFocusable={true}
                        ariaLabel="Bar grafiek"
                        barAriaLabel={({ id, value }) => {
                            let start = minU
                            if (id === 'midden') {
                                start = minP
                            } else if (id === 'eind') {
                                start = maxP
                            }
                            return start + " tot " + (start + value)
                        }}
                        enableLabel={false}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridX
                        enableGridY={false}
                        theme={{
                            "grid": {
                                "line": {
                                    "stroke": "#000",
                                }
                            }
                        }}

                        markers={values.map(({ value, id, color }) => {
                            let strokeDasharray = '1, 0'
                            if (id === 'Vergelijkbare pensioenfondsen') {
                                strokeDasharray = '16, 8'
                            } else if (id === 'Alle deelnemende pensioenfondsen') {
                                strokeDasharray = '0, 6'
                            }

                            return ({
                                axis: 'x',
                                value: value - minU,
                                lineStyle: {
                                    stroke: color,
                                    strokeLinecap: 'round',
                                    strokeWidth: 4,
                                    strokeDasharray: strokeDasharray,
                                }
                            })
                        })}

                        tooltip={({ id, value, color }) => {
                            let tekst = 'Range deelnemende pensioenfondsen'
                            let start = minU
                            let eind = maxU
                            if (id === 'midden') {
                                tekst = 'Range vergelijkbare pensioenfondsen'
                                start = minP
                                eind = maxP
                            }

                            return (
                                <div style={{
                                    background: 'white', color: 'inherit', fontSize: 'inherit',
                                    borderRadius: '2px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px', padding: '5px 9px'
                                }}>
                                    <div style={{ whiteSpace: 'pre', display: 'flex', alignItems: 'center' }}>
                                        <span style={{
                                            display: 'block', width: '12px', height: '12px',
                                            background: color, marginRight: '7px'
                                        }}></span>
                                        <span>
                                            {tekst}: <strong>{start}</strong> tot <strong>{eind}</strong>
                                        </span>
                                    </div>
                                </div>
                            )
                        }}
                    />
                </div>

                <strong className="ms-1">{maxU}</strong>
            </div>
        </div>
    )
}

export default BarChart